














































import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator'
import { FormField } from '@/models'
import * as monaco from 'monaco-editor'
import CodelessLoading from '@/components/CodelessLoading.vue'
import MonacoEditor, { editorEnv } from '@/plugins/monaco'
import ComponentEditorDialog from '@/components/ComponentEditorDialog.vue'
import gql from 'graphql-tag'

// @ts-ignore
@Component({
  // @ts-ignore
  components: {
    MonacoEditor,
    ComponentEditorDialog,
    CodelessLoading
  },
  apollo: {
    typescriptDefinitions: {
      query: gql`
        query typescriptDefinitions($environmentId: ID) {
          typescriptDefinitions(environmentId: $environmentId)
        }
      `,
      skip() {
        return (
          !this.environmentVariables && !this.environmentVariables.environmentId
        )
      },
      variables() {
        return {
          environmentId: this.environmentVariables.environmentId
        }
      },
      errorPolicy: 'ignore'
    }
  }
})
export default class CodeField extends Vue {
  /** Current Value */
  @Prop({ type: String, default: '' }) value!: string
  /** Field Schema */
  @Prop({ type: Object, default: () => ({}) }) schema!: FormField
  /** Disabled state */
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  /** Modal open */
  @PropSync('open', { type: Boolean, default: false }) isOpen!: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables!: Record<
    string,
    any
  >

  typings = ''

  codeEditorOptions = {
    automaticLayout: true
  }

  inlineEditComponentType = ''
  inlineEditComponentId = ''
  inlineEditComponentOpen = false

  @Watch('typescriptDefinitions')
  updateTypescriptDefinitions(tsd: string) {
    if (!tsd) return
    monaco.languages.typescript.javascriptDefaults.addExtraLib(
      tsd,
      '@simplexcl/codeless_database/index.d.ts'
    )
  }

  /** Validation Rules */
  get validationRules() {
    const rules = []
    // Required validation
    if (!this.schema.optional) {
      rules.push((v?: string) => !!v || this.$t('code_monaco.script.validationRules.required'))
    }
    return rules
  }

  /** Additional field options */
  get fieldOptions(): any {
    return this.schema.options || this.schema.fieldOptions || {}
  }

  get language() {
    const knownLanguages = {
      'text/css': 'css',
      'text/javascript': 'javascript',
      'text/html': 'ejs',
      'application/x-ejs': 'ejs',
      'application/json': 'json'
    } as Record<string, string>
    if (this.fieldOptions.mode) {
      return knownLanguages[this.fieldOptions.mode] || 'text'
    }
    return 'ejs'
  }

  get currentValue() {
    return this.value
  }

  set currentValue(newValue: string) {
    this.$emit('input', newValue)
  }

  editorDidMount(editor: monaco.editor.IStandaloneCodeEditor) {
    editorEnv.environmentId = this.environmentVariables.environmentId
    // Options
    const model = editor.getModel()
    model?.updateOptions({
      tabSize: 2,
      insertSpaces: true
    })

    // Actions
    editor.addAction({
      id: 'save',
      label: String(this.$t('code_monaco.script.editor.close')),
      keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KEY_S],
      run: () => {
        this.isOpen = false
      }
    })

    // Events
    editorEnv.onEditComponent = (componentType, componentId) => {
      this.inlineEditComponentOpen = true
      this.inlineEditComponentType = componentType
      this.inlineEditComponentId = componentId
    }
  }
}
